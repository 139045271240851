@keyframes header-headline-animation {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes header-description-animation {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  50% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
