/*
  Helpers that don't fit anywhere else
*/

.heading::after {
  border-bottom-style: solid;
  margin-top: .5em;
  margin-bottom: 1em;
  border-bottom-width: .2em;
  content: '';
  display: block;
  width: 2em;
}

.comp-blue {
  color: $comp-blue;
}

.comp-green {
  color: $comp-green;
}

.comp-red {
  color: $comp-red;
}

.comp-purple {
  color: $comp-purple;
}

.hidden {
  opacity: 0;
}
