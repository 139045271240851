
$white: #fff;
$black: #4A4A4A;

$comp-green: #9AEED0;
$comp-red: #FDABA9;
$comp-purple: #FCAAD3;
$comp-blue: #47CECD;

$text-color-primary: $white;
$text-color-secondary: $black;

$link-color: darken($comp-blue, 30%);
