header[role="banner"] {
  display: flex;
  flex-direction: column;
}

#site-header::before {
  background-image: url('/assets/images/top-background.svg');
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 40%;
  width: 100%;
  height: 0;
  min-height: 600px;
  content: '';
  position: absolute;
  z-index: -1;
}

#site-header a {
  color: $white;
  text-decoration: none;
  transition: all 0.2s;
  &:hover {
    letter-spacing: 0.1em
  }
}

#site-header nav {
  ul {
    margin: 0;
    padding: 1rem 0 0 1rem;
    @include laptop {
      padding: 1rem 0 0 5rem;
    }
  }
  li {
    display: inline-block;
    padding: 1rem;
    @include laptop {
      padding: 1rem;
    }
  }
}

#site-header .headline {
  overflow: hidden;
  padding-bottom: 5em;
  padding-top: 5%;
  padding-left: 40%;
  align-self: center;
  @include tablet {
    overflow: hidden;
    padding-top: 0;
    padding-left: 50%;
  }
  @include laptop {
    overflow: hidden;
    padding-left: 45%;
  }
}

#site-header #headline-heading {
  animation: header-headline-animation 1s;
}

#site-header #headline-description {
  animation: header-description-animation 2s;
}

#site-header .hero-image {
  max-width: 70%;
  box-sizing: content-box;
  position: relative;
  align-self: center;
  border-radius: 100%;
  border-width: 10px;
  border-style: solid;
  margin-bottom: 5em;
  @include mobile {
    max-width: 50%;
  }
  @include tablet {
    margin-bottom: 0;
    max-width: 350px;
    left: inherit;
  }
  @include laptop {
    max-width: 400px;
    top: -10em;
    right: 15%;
  }
}

main[role="main"] {
  display: flex;
  flex-direction: column;
}

.home-intro, .home-about, .home-work {
  width: 90vw;
  max-width: 1200px;
  margin-top: 5em;
  margin-bottom: 5em;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.topic {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-code::before {
  background-image: url('/assets/images/code-background.svg');
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 100%;
  width: 100%;
  height: 0;
  min-height: 1500px;
  content: '';
  position: absolute;
  z-index: -1;
}

.topic-headline {
  overflow: hidden;
  padding-bottom: 5%;
  padding-top: 120%;
  padding-left: 10vw;
  padding-right: 10vw;
  align-self: center;
  @include mobile {
    padding-top: 90%;
  }
  @include tablet {
    padding-top: 50%;
  }
  @include laptop {
    padding-top: 40%;
  }
}

.headline-left {
  padding-right: 15%;
  @include tablet {
    padding-right: 40%;
  }
}

.headline-right {
  margin-top: 15%;
  margin-bottom: -5%;
  padding-left: 15%;
  @include tablet {
    padding-left: 40%;
  }
}

.skill-cloud {
  overflow: hidden;
  padding: 0;
  text-align: center;
  margin-left: 5vw;
  margin-right: 5vw;
  margin-bottom: 140%;
  margin-top: 4%;
  @include mobile {
    margin-bottom: 120%;
  }
  @include tablet {
    margin-bottom: 100%;
  }
  @include laptop {
    margin-bottom: 80%;
  }
  @include hd {
    margin-bottom: 60%;
  }
}

.skill-cloud li {
  list-style: none;
  float: right;
}

@for $i from 1 through 10 {
  .skill-cloud li:nth-child(#{$i}n) {
    $i: percentage(random(3)/100);
    padding: $i;
    @include tablet {
      padding: $i * 1.2;
    }
    @include laptop {
      padding: $i * 1.4;
    }
    @include hd {
      padding: $i * 1.6;
    }
  }
}

.home-design::before {
  background-image: url('/assets/images/design-background.svg');
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 120%;
  width: 100%;
  height: 100%;
  min-height: 1500px;
  content: '';
  position: absolute;
  z-index: -1;
}
footer[role="contentinfo"]::before {
  background-image: url('/assets/images/footer-background.svg');
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 40%;
  width: 100%;
  height: 0;
  min-height: 600px;
  content: '';
  z-index: -1;
  display: block;
  overflow: hidden;
}

footer[role="contentinfo"] {
  overflow: hidden;
  position: relative;
}

footer[role="contentinfo"] .contact-info-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 3%;
  max-width: 600px;
}


footer[role="contentinfo"] .contact-info-container .heading {
  position: absolute;
  top: -3em;
  right: auto;
  padding-right: 33%;
}

footer[role="contentinfo"] .contact-info-container a {
  width: 20%;
  margin: 5%;
  max-width: 100px;
  fill: $white;
  @include tablet {
    width: 15%;
    max-width: 200px;
  }
  @include laptop {
    max-width: 250px;
    margin: 2em;
  }
}

footer[role="contentinfo"] .contact-info-container svg {
  width: 100%;
}

#linkedin-icon:hover {
  fill: #369ADA;
}

#mail-icon:hover {
  fill: teal;
}

#git-icon:hover {
  fill: #333;
}
