/*
  Basic elements, simple
*/

body {
  min-height: 100vh;
}

a {
  color: $comp-purple;
  &:hover {
    color: darken($comp-purple, 10%);
  }
}

.cursor {
  font-weight: 900;
}
